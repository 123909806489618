import React from "react"
import Layout from "../components/layout.js"
import * as styles from "./register.module.css"
import Meta from "../components/meta.js"
export default function RegisterInter() {
    return (
        /*<div>
            <head>
                <title>HTML Meta Tag</title>
                {/!*<meta
              http-equiv="refresh"
              content="2; url = https://docs.google.com/document/d/19lHB3ADtHeuM-JQqSfNpNeAGjQiAQVUPm3L45sj9OYU/edit"
            />*!/}
            </head>
            <Layout />

            <div
                style={{
                    width: `100%`,
                }}
            >
                {/!* <p>Redirecting to form</p> *!/}
                <iframe className={styles.frame}
                        style={{
                            display: `block`,
                            margin: `3rem auto`,
                            height: `550px`,

                        }}
                        src="https://docs.google.com/document/d/19lHB3ADtHeuM-JQqSfNpNeAGjQiAQVUPm3L45sj9OYU/edit"
                        width="100%"
                        frameborder="0"
                        marginwidth="0"
                >
                    Loading…
                </iframe>
            </div>
        </div>*/
        <Layout>
            <Meta />
            <meta http-equiv="refresh" content="0;url=https://drive.google.com/file/d/1_OaLvg9sFOykOL4VmqsAMbIxe6MWZxx5/view?usp=sharing" />
        </Layout>
    )
}
